.app {
  text-align: center;
  box-sizing: border-box;
  position: fixed;
  height: 100%;
  width: 100%;
}

.appLogo {
  height: 100%;
  pointer-events: none;
  margin-right: 20px;
}

h1, h2 {
  font-weight: 400;
  font-size: 20px;
}

.viewer {
  font-size: 30px;
  margin: 0 20px;
}

.title {
  margin-top: 8px;
  display: inline-flex;
  align-items: baseline;
  font-size: 20px;
}

.title h1, .title h2 {
  font-size: 20px;
  margin-right: 5px;
}

.grey {
  color: #717274;
}

.appHeader {
  box-sizing: border-box;
  padding: 10px;
  background-color: white;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  height: 60px;
  max-height: 60px;
  border-bottom: var(--darkgrey) 5px solid;
  user-select: none;
  position: relative;
}

.modelContainer {
  margin: 0;
}

.modelContainer > canvas {
  margin: 0;
  width: 100vw !important;
  height: calc(100% - 120px) !important;
}

.loading {
  background-color: var(--dark);
  position: absolute;
  top: 60px;
  width: 100vw;
  height: calc(100% - 60px);
  z-index: 100;
}

.loading > div {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  width: 100%;
  height: 100%;
}

.loading p {
  font-size: 16pt;
  padding-top: 20px;
  color: white;
  font-weight: 400;
  scale: 1;
  animation: scale 1.8s ease-in-out infinite;
}

p.error {
  animation: unset;
  padding: 20px;
}

.hidden {
  display: none !important;
}

.loader {
  scale: 1.3;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  max-width: 6rem;
  margin-top: 3rem;
  margin-bottom: 3rem;
}
.loader:before,
.loader:after {
  content: "";
  position: absolute;
  border-radius: 50%;
  animation: pulsOut 1.8s ease-in-out infinite;
  filter: drop-shadow(0 0 0.5rem rgba(255, 255, 255, 0.75));
}
.loader:before {
  width: 100%;
  padding-bottom: 100%;
  box-shadow: inset 0 0 0 0.5rem var(--yellow);
  animation-name: pulsIn;
}
.loader:after {
  width: calc(100% - 1rem);
  padding-bottom: calc(100% - 1rem);
  box-shadow: 0 0 0 0 var(--yellow);
}

.overlay {
  width: 100vw;
  height: calc(100% - 60px);
  background: rgba(29, 44, 53, 0.4);
  position: absolute;
  top: 60px;
  z-index: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: auto;
}

.overlayContent {
  position: relative;
  padding: 50px;
  background: rgb(42, 59, 70); 
  box-shadow: 2px 2px 0px 0px rgba(255,255,255,0.25);
  color: white;
  border: rgba(255, 255, 255, 0.6) 1px solid
}

.overlayContent h3 {
  font-size: 30px;
  border-bottom: #ffdd02 1px solid;
  padding-bottom: 10px;
  font-weight: 500;
  color: #ffdd02;
  margin-top: 0;
}

.overlayContent p {
  color: white;
  margin: 5px 0;
}

p.break {
  margin-top: 20px;
}

.close {
	overflow: hidden;
	position: absolute;
  right: 10px;
  top: 10px;
	border: none;
	padding: 0;
	width: 2em; height: 2em;
	border-radius: 50%;
	background: transparent;
	color: var(--yellow);
	font: inherit;
	text-indent: 100%;
	cursor: pointer;
}

.close:focus {
  outline: solid 0 transparent;
  box-shadow: 0 0 0 2px white
}

.close:hover {
  background: rgba(0, 0, 0, .5)
}

.close:before, .close:after {
  position: absolute;
  top: 15%; left: calc(50% - .0625em);
  width: .125em; height: 70%;
  border-radius: .125em;
  transform: rotate(45deg);
  background: currentcolor;
  content: ''
}

.close:after { transform: rotate(-45deg); }

.test {
  position: absolute;
  right: 0;
  bottom: 0;
}

@keyframes pulsIn {
  0% {
    box-shadow: inset 0 0 0 0.5rem var(--yellow);
    opacity: 1;
  }
  50%, 100% {
    box-shadow: inset 0 0 0 0 var(--yellow);
    opacity: 0;
  }
}

@keyframes pulsOut {
  0%, 50% {
    box-shadow: 0 0 0 0 var(--yellow);
    opacity: 0;
  }
  100% {
    box-shadow: 0 0 0 0.5rem var(--yellow);
    opacity: 1;
  }
}

@keyframes scale {
  0% {
    scale: 1;
  }
  50% {
    scale: 1.1;
  }
  100% {
    scale: 1;
  }
}

@media only screen and (max-width: 500px) {
  .App-header {
    justify-content: left;
    height: 60px;
    padding-left: 0;
  }

  h1, h2 {
    display: none;
  }

  .appLogo {
    position: absolute;
    height: 40px;
    margin-left: 100px;
    pointer-events: none;
    right: 10px;
  }
}

@media only screen and (max-height: 500px) {
  .appLogo, h1, h2 {
    display: none;
  }

  .appHeader {
    height: 0;
    padding: 0;
    border: none;
  }

  .modelContainer > canvas {
    margin: 0;
    position: absolute;
    right: 0;
    width: calc(100% - 60px) !important;
    height: 100% !important;
  }

  .loading {
    top: 0px;
    width: 100%;
    height: 100%;
    z-index: 100;
  }
}

@media only screen and (max-width: 750px) {
  .appLogo {
    margin-right: 20px;
  }
  .viewer {
    display: none;
  }
}

@media only screen and (max-width: 750px) and (orientation:portrait) {
  .overlayContent {
    box-sizing: border-box;
    padding: 20px;
    min-height: 100%;
  }

  .overlay {
    top: 60px;
    align-items: flex-start;
  }
}

@media only screen and (max-width: 750px) and (orientation:landscape) {
  .overlayContent {
    box-sizing: border-box;
    padding: 20px;
    min-height: 100%;
    width: calc(100% - 60px);
    left: 0;
    top: 0;
    position: absolute;
  }

  .overlay {
    top: 0;
    left: 60px;
    align-items: flex-start;
    height: 100%;
    width: 100%;
  }
}
    
  
