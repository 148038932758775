footer {
  box-sizing: border-box;
  background-color: white;
  border-top: var(--darkgrey) 5px solid;
  user-select: none;
  max-height: 60px;
}

.spacer {
  width: 10px;
  height: 50px;
  display: inline-block;
  position: relative;
  background: white;
}

.spacer div {
  width: 1px;
  height: 100%;
  border-right: 1px solid black;
  margin: 0 3px;
}

@media only screen and (max-height: 500px) {
  footer {
    border-top: none;
    border-right: var(--darkgrey) 5px solid;
    position: absolute;
    display: flex;
    width: 60px;
    left: 0;
    top: 55px;
    flex-direction: column;
    justify-content: space-around;
    min-height: calc(100% - 55px);
    height: calc(100% - 55px);
    max-height: calc(100% - 55px);
  }

  .spacer {
    height: 10px;
    width: 50px;
    display: inline-block;
    position: relative;
    background: white;
  }

  .spacer div {
    width: 100%;
    height: 1px;
    border-bottom: 1px solid black;
    margin: 0 3px;
  }
}

@media only screen and (max-width: 500px) {
  footer {
    display: flex;
    justify-content: space-around;
  }

  .spacer {
    width: 1px;
  }
}


