ul {
  padding: 0 20px;
}

ul li {
  list-style: none;
}

p.link {
  text-decoration: underline;
  cursor: pointer;
}

.expandContainerMenu {
  background-color: white;
  position: absolute;
  top: 60px;
  left: 0;
  display: grid;
  column-gap: 10px;
  border-right: 5px solid var(--darkgrey);
  border-left: 5px solid white;
  border-bottom: 5px solid var(--darkgrey);
  border-top: 5px solid white;
  opacity: 1;
  animation-name: appear;
  animation-duration: 0.7s;
  border-radius: 5px;
  padding: 10px;
  min-width: 220px;
  text-align: left;
  z-index: 10;
}


@media only screen and (max-height: 500px) {
  .menuButton {
    border-right: 5px solid var(--darkgrey);
  }

  .expandContainerMenu {
    left: 55px;
    top: 0;
    z-index: 200;
  }
}