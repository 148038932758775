.helpContainer {
  width: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  color: white;
  overflow: auto;
}

.helpContainer p {
  color: white;
  text-align: left;
}

.helpContent {
  width: 750px;
}

.helpContent > p {
  text-align: left;
  margin-top: -15px;
  margin-bottom: 20px;
}

.icon {
  width: 50px;
}

.text {
  width: calc(100% - 50px);
}

h4 {
  text-align: left;
  color: var(--yellow);
  font-weight: 500;
  margin-top: 30px;
}

@media only screen and (max-width: 750px) and (orientation:landscape) {
  .helpContent {
    width: calc(100% - 60px);
  }
}