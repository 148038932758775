:root {
  --red: #b00;
  --blue: #00b;
  --fullwidth: 100%;
  --green: #1e682f;
  --lightgrey: #f0f0f0;
  --darkgrey: #dddddd;
  --yellow: #ffdd00;
  --dark: #1d2c35;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  box-sizing: border-box;
}
