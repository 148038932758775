.expandContainer {
  padding: 20px;
  text-align: left;
  min-width: 150px;
  background-color: white;
  position: absolute;
  bottom: 60px;
  left: 0px;
  display: grid;
  column-gap: 10px;
  border-right: 5px solid var(--darkgrey);
  border-left: 5px solid var(--darkgrey);
  border-top: 5px solid var(--darkgrey);
  border-bottom: 5px solid white;
  opacity: 1;
  animation-name: appear;
  animation-duration: 0.7s;
  border-radius: 5px;
}

.expandContainerElevation {
  background-color: white;
  position: absolute;
  bottom: 60px;
  left: 0px;
  display: grid;
  column-gap: 10px;
  border-right: 5px solid var(--darkgrey);
  border-left: 5px solid var(--darkgrey);
  border-top: 5px solid var(--darkgrey);
  border-bottom: 5px solid white;
  opacity: 1;
  animation-name: appear;
  animation-duration: 0.7s;
  border-radius: 5px;
}

.expanded:hover {
  background-color: white !important;
}

.expandContainer input {
  accent-color: var(--dark);
}

.readonly {
  position: absolute;
  background: white;
  width: 100%;
  height: calc(100% - 10px);
  opacity: 0.7;
}

.groupContainer {
  display: block;
  position: relative;
}

.groupLeft {
  width: 100%;
  font-weight: 600;
  font-size: 14px;
  margin-bottom: 3px;
}

.groupRight {
  color: black;
  margin-left: 20px;
}

.groupRight:last-child {
  margin-bottom: 10px;
}

@keyframes appear {
  from {opacity: 0;}
  to {opacity: 1;}
}

@media only screen and (max-width: 500px) {
  .expandContainer {
    right: 0;
    left: unset;
    border-right: 5px solid white;
  }
}

@media only screen and (max-height: 500px) {
  .expandContainer {
    min-width: 150px;
    background-color: white;
    position: absolute;
    bottom: -5px;
    left: 55px;
    display: grid;
    column-gap: 10px;
    border-right: 5px solid var(--darkgrey);
    border-left: 5px solid white;
    border-top: 5px solid var(--darkgrey);
    border-bottom: 5px solid white;
    width: calc(100vw - 60px);
  }

  .groupContainer {
    display: inline-block;
    position: relative;
    vertical-align: top;
    margin-right: 20px;
  }

  .expandContainerElevation {
    bottom: -5px;
    left: 55px;
    border-right: 5px solid var(--darkgrey);
    border-left: 5px solid white;
    border-top: 5px solid var(--darkgrey);
    border-bottom: 5px solid var(--darkgrey);
    max-height: 50px;
  }

  .expandContainerElevation div {
    display: flex;
    flex-direction: row;
    max-height: 50px;
    padding: 0 10px;
  }
}