.navigationButton {
  height: 60px;
  vertical-align: top;
  border-width: 0;
  background-color: white;
  position: relative;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  font-size: 11pt;
  padding: 0 5px;
  display: inline-flex;
}

.navigationButton:hover {
  background-color: var(--lightgrey);
}

.navigationButton:active {
  background-color: white;
}

.navigationButton > p.smallText {
  margin: 0;
}

.selected {
  margin-top: -10px;
  border-top: 10px var(--yellow) solid;
  height: 60px;
  border-radius: 10px 10px 0 0;
}

.navigationButton:hover > svg, p {
  color: var(--dark);
}

.navigationButton > svg {
  margin-top: 5px;
}

.navigationButton > p {
  margin: 5px;
  margin-top: 0px;
}

@media only screen and (max-width: 500px) {
  .iconText {
    display: none;
  }

  .navigationButton {
    padding: 0 20px;
  }
}

@media (hover: none) {
  .hideOnMobile {
    display: none;
  }
}

@media only screen and (max-height: 500px) {
  .navigationButton {
    padding: 0px 10px;
    margin: 0;
  }
  .iconText {
    display: none;
  }

  .selected {
    border-top: none;
    margin-right: -10px;
    border-right: 10px var(--yellow) solid;
    border-radius: 0 10px 10px 0;
  }
}